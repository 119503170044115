








































































































































































































































































































































import mixin from './mixin';
import virtualTableMixin from '@/mixins/virtualTableMixin';
import { mapGetters, mapMutations } from 'vuex';
import {
  coccMtdsResource_1_cocc_builder_constructMergeRepeat,
  confirmAndReassemblePrice,
  rcjTotalAndCache,
  delRcjCache,
  saveRcjCache,
} from '@/api/projectDetail';
import { setTableHeaderWidth } from '@/utils';
import TenderFolderForList from '@/views/projects/detail/engineeringProject/tenderFolderForList.vue';
import EditableCell from './EditableCell.vue';
import Vue from 'vue';
import TenderProvisionalEstimate from '@/views/projects/detail/engineeringProject/tenderProvisionalEstimate.vue';

export default Vue.extend({
  name: 'artificialAndMechanicalSummary',
  mixins: [mixin, virtualTableMixin],
  data() {
    return {
      tableData: [],
      visible: false,
      loading: false,
      footerHeight: 0, // 底部按钮以及描述高度
      currentInfo: null, // 点击是否甲供 时当前行数据
      isFinish: false, // 是否完成 甲供材料对应操作
      isShowFooter: false, // 是否显示底部按钮
      btnLoading: false, // 底部按钮loading
      page: 1,
      limit: 20,
      scrollSwitch: false, // 是否重新请求数据
      scrollTop: 0, // 滑动距离
      typeList: [], // 1 人工 2 材料 3 机械 4 设备
      filteredInfo: null,
      /* 锁定材价数据 */
      lockStandardPriceVisible: false,
      currentRecord: null,
      currentRecord_index: 0,
      /* 应用缓存数据 */
      useCacheVisible: false,
      useCacheBtnLoading: false,
      currentCacheFlag: false, //是否使用缓存数据标志
      currentCacheData: null, //临时存储缓存数据
      /* 重置提交数据 */
      handleResetVisible: false,
      handleResetConfirmLoading: false,
      total: 0, // 数据总条数
      filterType: null, // 筛选类型  1 人 2 材 3机械 4设备   7甲供 材料/设备 8暂估价 材料/设备 9 锁定价 材料/设备
      filterMenu: [
        {
          title: '全部人材机',
          children: [
            {
              title: '人工',
              key: 1,
            },
            {
              title: '材料',
              key: 2,
            },
            {
              title: '机械',
              key: 3,
            },
            {
              title: '设备',
              key: 4,
            },
          ],
        },
        {
          title: '甲供材料设备',
          key: 7,
        },
        {
          title: '暂估材料设备',
          key: 8,
        },
        {
          title: '锁定价格',
          key: 9,
        },
      ],
      setType: 0, // 1 甲供 2 暂估
      isTableShow: false, // table表格是否全屏展示
      provisionalEstimateVisible: false,
      rcjName: '', // 材料名称搜索
      optionType: 2, // 1：处理缓存数据，2：一般列表查询  3:筛选数据
    };
  },
  components: { TenderProvisionalEstimate, TenderFolderForList, EditableCell },
  computed: {
    ...mapGetters([
      'sequenceNbr',
      'bidSequenceNbr',
      'artificialSummary',
      'projectSequenceNbr',
      'projectBidSequenceNbr',
      'isPriceAdjustmentOrValuation',
      'isPriceAdjustment',
      'combinedPriceStatus',
      'listGenerating',
      'refreshTreeFrom',
    ]),
    tableScroll() {
      const num: number =
        (this as any).componentHeight -
        (this as any).tableHeadHeight -
        3 -
        this.footerHeight -
        30; // 30为底部距离表格的边距
      return { y: num, x: 800 };
    },
    columns() {
      let { filteredInfo }: any = this as Vue;
      filteredInfo = filteredInfo || {};
      const columns = [
        {
          title: '编码信息',
          children: [
            {
              key: 'rcjName',
              title: '材料名称',
              dataIndex: 'rcjName',
              align: 'center',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'rcjName',
              },
              width: 150,
            },
            {
              key: 'specification',
              title: '规格',
              dataIndex: 'specification',
              align: 'center',
              width: 100,
            },
            {
              key: 'kind',
              title: '类型',
              dataIndex: 'kind',
              align: 'center',
              scopedSlots: { customRender: 'kind' },
              width: 100,
            },
            {
              key: 'unit',
              title: '单位',
              dataIndex: 'unit',
              align: 'center',
              width: 80,
            },
          ],
        },
        {
          title: '数量价格',
          children: [
            {
              key: 'calcQty',
              title: '数量',
              dataIndex: 'calcQty',
              align: 'center',
              width: 100,
            },
            {
              key: 'standardPrice',
              title: '市场价(元)',
              dataIndex: 'standardPrice',
              align: 'center',
              scopedSlots: { customRender: 'standardPrice' },
              width: 100,
            },
            {
              key: 'marketPriceTotal',
              dataIndex: 'marketPriceTotal',
              align: 'center',
              slots: { title: 'marketPriceTotalTitle' },
              scopedSlots: { customRender: 'marketPriceTotal' },
              width: 100,
            },
            {
              key: 'ifLockStandardPrice',
              title: '锁定材价',
              dataIndex: 'ifLockStandardPrice',
              align: 'center',
              scopedSlots: { customRender: 'ifLockStandardPrice' },
              width: 100,
            },
          ],
        },
        {
          title: '甲供操作',
          children: [
            {
              key: 'ifDonorMaterial',
              title: '是否甲供',
              dataIndex: 'ifDonorMaterial',
              align: 'center',
              scopedSlots: { customRender: 'ifDonorMaterial' },
              width: 100,
            },
            {
              key: 'donorMaterialName',
              title: '甲供材料',
              dataIndex: 'donorMaterialName',
              align: 'center',
              scopedSlots: { customRender: 'donorMaterialName' },
              width: 100,
            },
            {
              key: 'donorMaterialPrice',
              title: '甲供价格',
              dataIndex: 'donorMaterialPrice',
              align: 'center',
              scopedSlots: { customRender: 'donorMaterialPrice' },
              width: 100,
            },
          ],
        },
        {
          title: '暂估操作',
          children: [
            {
              key: 'ifProvisionalEstimate',
              title: '是否暂估',
              dataIndex: 'ifProvisionalEstimate',
              align: 'center',
              scopedSlots: { customRender: 'ifProvisionalEstimate' },
              width: 100,
            },
            {
              key: 'provisionalEstimateName',
              title: '暂估名称',
              dataIndex: 'provisionalEstimateName',
              align: 'center',
              scopedSlots: { customRender: 'provisionalEstimateName' },
              width: 100,
            },
            {
              key: 'provisionalEstimatePrice',
              title: '暂估价格',
              dataIndex: 'provisionalEstimatePrice',
              align: 'center',
              scopedSlots: { customRender: 'provisionalEstimatePrice' },
              width: 100,
            },
          ],
        },
      ];
      return columns;
    },
  },
  watch: {
    projectBidSequenceNbr: function (val, oldVal) {
      if (val !== oldVal) {
        console.log('projectBidSequenceNbr');
        this.getInit();
        this.getSingleSummary();
      }
    },
    artificialSummary: function (val) {
      if (val) {
        this.$store.commit('SET_ARTIFICIAL_SUMMARY', false);
      }
    },
    tableData: {
      handler: function (newVal) {
        console.log('newVal', newVal);
        if (this.optionType === 3) return;
        if (newVal && newVal.length) {
          const target = newVal.find(
            (item: any) =>
              item.kind != item.old_kind ||
              item.standardPrice != item.old_standardPrice ||
              item.ifLockStandardPrice != item.old_ifLockStandardPrice ||
              item.ifDonorMaterial != item.old_ifDonorMaterial ||
              item.donorMaterialName != item.old_donorMaterialName ||
              item.donorMaterialPrice != item.old_donorMaterialPrice ||
              item.ifProvisionalEstimate != item.old_ifProvisionalEstimate ||
              item.provisionalEstimateName !=
                item.old_provisionalEstimateName ||
              item.provisionalEstimatePrice != item.old_provisionalEstimatePrice
          );
          this.isShowFooter = !!target;
        } else {
          this.isShowFooter = false;
        }
        // this.isShowFooter = newVal.some((item) => {
        //   return item.ifDonorMaterial === '1';
        // });
        this.setFooterHeight();
        console.log('isShowFooter', this.isShowFooter);
      },
      immediate: true,
      deep: true,
    },
    isPriceAdjustmentOrValuation: function (val) {
      if (!val) {
        console.log('重新计价完成');
        this.typeList = [];
        this.getInit();
        this.getSingleSummary();
      }
    },
    refreshTreeFrom: function (val, oldVal) {
      if (oldVal === 'combinedPrice') {
        this.typeList = [];
        this.getInit();
        this.getSingleSummary();
      }
    },
  },
  created() {
    this.$store.commit('SET_ARTIFICIAL_SUMMARY', false);
    this.getInit();
    console.log('created');
    this.getSingleSummary(1);
    this.setFooterHeight();
    // window.addEventListener('resize', this.getTableHeaderWidth);
  },
  mounted() {
    setTimeout(() => {
      this.moreLoad();
    }, 500);
  },
  methods: {
    ...mapMutations(['SET_PRICE_ADJUSTMENT_VALUATION']),
    getInit(status = 0) {
      this.page = 1;
      this.tableData = [];
      if (!status) this.filteredInfo = null;
    },
    rowClassName(record) {
      if (
        this.isFinish &&
        this.currentInfo &&
        record.sequenceNbr === (this.currentInfo as any).sequenceNbr
      ) {
        return 'animationFlicker';
      }
      return '';
    },
    getTableHeaderWidth() {
      setTableHeaderWidth('.artificial-summary-list');
    },
    // 左侧筛选点击事件
    treeSelect(selectedKeys, e: { selected; selectedNodes; node; event }) {
      console.log('selectedKeys', selectedKeys, e);
      this.filterType = selectedKeys.toString();
      this.tableData = [];
      this.page = 1;
      if (!this.isShowFooter) {
        this.getSingleSummary();
      } else {
        this.optionType = 3;
        this.getSingleSummary(3);
      }
    },
    /* 列表数据查询接口 useCache参数用来判断 1：处理缓存数据，2：一般列表查询  3:筛选数据*/
    getSingleSummary(useCache = 2) {
      const sequenceNbr = this.projectBidSequenceNbr;
      if (!sequenceNbr) return '';
      this.loading = true;
      let apiData = {
        constructId: sequenceNbr,
        type: this.filterType ? Number(this.filterType) : null,
        page: this.page,
        limit: this.limit,
        name: this.rcjName,
      };
      rcjTotalAndCache(apiData)
        .then((res) => {
          if (res.status === 200) {
            this.loading = false;
            this.btnLoading = false;
            this.isFinish = false;
            this.total = res.result.total;
            if (useCache === 1) {
              this.handleCacheData(
                res.result.cacheFlag,
                res.result.udcRenCaiJiModelList
              );
            } else if (useCache === 2) {
              this.inittableData(res.result.udcRenCaiJiModelList);
            } else {
              this.tableData = this.tableData.concat(
                (this as any).transformData(res.result.udcRenCaiJiModelList)
              );
              this.getTableHeaderWidth();
              if (Math.ceil(this.total / this.limit) > this.page) {
                this.scrollSwitch = true;
              } else {
                this.scrollSwitch = false;
              }
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /* 应用缓存 */
    handleUseCacheOk() {
      console.log('handleUseCacheOk');
      this.inittableData(this.currentCacheData);
      this.useCacheVisible = false;
      this.currentCacheFlag = true; //当前数据已经应用缓存
    },
    /* 取消缓存 */
    handleUseCacheCancel() {
      console.log('handleUseCacheCancel');
      this.handleDelRcjCache();
    },
    /* 处理当前缓存数据 */
    handleCacheData(cacheFlag, currentData) {
      if (cacheFlag) {
        console.log('handleCacheData-有缓存');
        this.currentCacheData = currentData;
        this.useCacheVisible = true;
      } else {
        console.log('handleCacheData-无缓存');
        this.inittableData(currentData);
      }
    },
    /* 删除缓存接口 */
    handleDelRcjCache() {
      const sequenceNbr = this.projectBidSequenceNbr;
      if (!sequenceNbr) return '';
      let apiData = {
        constructId: sequenceNbr,
        typeList: this.typeList,
        page: this.page,
        limit: this.limit,
      };
      this.useCacheBtnLoading = true;
      delRcjCache(apiData)
        .then((res) => {
          if (res.status === 200) {
            this.currentCacheFlag = false; //当前数据没有应用缓存
            this.useCacheVisible = false;
            this.getInit();
            this.getSingleSummary();
          }
        })
        .finally(() => {
          this.useCacheBtnLoading = false;
        });
    },
    /* 保存人材机某一条数据的临时缓存 */
    handleSaveRcjCache(record) {
      console.log('handleSaveRcjCache');
      const sequenceNbr = this.projectBidSequenceNbr;
      if (!sequenceNbr) return '';
      let apiData = {
        constructId: sequenceNbr,
        ifDonorMaterial: record.ifDonorMaterial,
        ifLockStandardPrice: record.ifLockStandardPrice,
        donorMaterialName: record.donorMaterialName,
        donorMaterialPrice: record.donorMaterialPrice,
        kind: record.kind,
        rcjId: record.rcjId,
        standardPrice: record.standardPrice,
        materialSequenceNbr: record.materialSequenceNbr,
        donorSpecification: record.donorSpecification,
        ifProvisionalEstimate: record.ifProvisionalEstimate,
        provisionalEstimateName: record.provisionalEstimateName,
        provisionalEstimatePrice: record.provisionalEstimatePrice,
      };
      saveRcjCache(apiData);
    },
    //初始化列表数据tableData以及样式
    inittableData(newData) {
      console.log('inittableData');
      const currentNewData = newData.map((item: any) => {
        return {
          ...item,
          old_kind: item.kind,
          old_standardPrice: item.standardPrice,
          old_ifLockStandardPrice: item.ifLockStandardPrice,
          old_ifDonorMaterial: item.ifDonorMaterial,
          old_donorMaterialName: item.donorMaterialName,
          old_donorMaterialPrice: item.donorMaterialPrice,
          old_ifProvisionalEstimate: item.ifProvisionalEstimate,
          old_provisionalEstimateName: item.provisionalEstimateName,
          old_provisionalEstimatePrice: item.provisionalEstimatePrice,
        };
      }) as any;

      this.tableData = this.tableData.concat(
        (this as any).transformData(currentNewData)
      );
      console.log('tableData', this.tableData);
      // this.tableData = this.tableData.map((item: any) => {
      //   return {
      //     ...item,
      //     old_kind: item.kind,
      //     old_standardPrice: item.standardPrice,
      //     old_ifLockStandardPrice: item.ifLockStandardPrice,
      //     old_ifDonorMaterial: item.ifDonorMaterial,
      //     old_donorMaterialName: item.donorMaterialName,
      //     old_donorMaterialPrice: item.donorMaterialPrice,
      //   };
      // }) as any;
      this.getTableHeaderWidth();
      if (Math.ceil(this.total / this.limit) > this.page) {
        this.scrollSwitch = true;
      } else {
        this.scrollSwitch = false;
      }
    },
    moreLoad() {
      const tableEl = document.querySelector(
        '.artificial-mechanical-list .artificial-mechanical-content .ant-table-body'
      ) as HTMLDivElement;
      tableEl.onscroll = () => {
        // 滚动条的高度 + 可视区域的高度 >= 滚动里面的总高度时，就说明滚动到底部了
        if (
          Math.ceil(tableEl.scrollTop + tableEl.clientHeight + 4) >=
          tableEl.scrollHeight
        ) {
          if (this.scrollSwitch && !this.loading) {
            console.log('到底啦~', this);
            this.scrollTop = tableEl.scrollTop;
            this.page++;
            this.getSingleSummary();
          }
        }
      };
    },
    /* 更改类型事件 */
    handleMenuClick(e, record) {
      record.kind = e.key.toString();
      this.handleSaveRcjCache(record); // 更新缓存数据
    },
    /* 锁定材价 CheckBox点击事件*/
    ifLockStandardPriceChange(e, record, index) {
      this.currentRecord = record;
      this.currentRecord_index = index;

      if (e.target.checked) {
        this.lockStandardPriceVisible = true;
      } else {
        record.ifLockStandardPrice = '0';
        this.handleSaveRcjCache(record); // 更新缓存数据
      }
    },
    /* 更改锁定材价数据 */
    updateLockStandardPrice() {
      (
        (this as any).renderData[this.currentRecord_index] as any
      ).ifLockStandardPrice = '1';
      this.lockStandardPriceVisible = false;
      this.handleSaveRcjCache(this.currentRecord); // 更新缓存数据
    },
    /* 市场价格修改事件 */
    standardPriceChange(record, value) {
      console.log('standardPriceChange:', value);
      record.standardPrice = value;
      this.handleSaveRcjCache(record); // 更新缓存数据
    },
    /**
     * 是否甲供 CheckBox点击事件
     */
    checkChange(e, record) {
      if (e.target.checked) {
        this.setType = 1;
        this.currentInfo = record;
        this.visible = true;
        this.isFinish = false;
      } else {
        record.donorMaterialName = null;
        record.donorMaterialPrice = null;
        record.materialSequenceNbr = null;
        record.ifDonorMaterial = '0';
        this.setType = 0;
        this.handleSaveRcjCache(record); // 更新缓存数据
      }
    },
    provisionalEstimateCheckChange(e, record) {
      if (e.target.checked) {
        this.setType = 2;
        this.currentInfo = record;
        this.provisionalEstimateVisible = true;
        this.isFinish = false;
      } else {
        record.provisionalEstimateName = null;
        record.provisionalEstimatePrice = null;
        record.provisionalEstimateSequenceNbr = null;
        record.ifProvisionalEstimate = '0';
        this.setType = 0;
        this.handleSaveRcjCache(record); // 更新缓存数据
      }
    },
    openEdit(record, type) {
      this.currentInfo = record;
      if (type === 1) {
        this.visible = true;
      } else {
        this.provisionalEstimateVisible = true;
      }
      this.isFinish = false;
    },
    filterChange(pagination, filters, sorter, { currentDataSource }) {
      console.log('filters', filters);
      this.filteredInfo = filters;
      this.typeList = filters.kind.filter((item) => {
        return Number(item);
      });
      this.getInit(1);
      this.getSingleSummary();
    },
    getPopupContainer() {
      return document.getElementById('ysf_body');
    },
    updateData(record) {
      (this as any).renderData.filter((x: any) => {
        if (x.sequenceNbr === record.sequenceNbr) {
          x.donorMaterialName = record.donorMaterialName;
          x.donorMaterialPrice = record.donorMaterialPrice;
          x.materialSequenceNbr = record.materialSequenceNbr;
          x.ifDonorMaterial = '1';
          x.ifLockStandardPrice = '0'; //勾选甲供时取消锁定材价勾选
          if (!record.donorMaterialName) {
            x.ifDonorMaterial = '0';
          } else {
            this.isFinish = true;
          }
          this.handleSaveRcjCache(x); // 更新缓存数据
        }
      });
    },
    updateProvisionalEstimateData(record) {
      (this as any).renderData.filter((x: any) => {
        if (x.sequenceNbr === record.sequenceNbr) {
          x.provisionalEstimateName = record.provisionalEstimateName;
          x.provisionalEstimatePrice = record.provisionalEstimatePrice;
          x.provisionalEstimateSequenceNbr =
            record.provisionalEstimateSequenceNbr;
          x.ifProvisionalEstimate = '1';
          x.ifLockStandardPrice = '0'; //勾选甲供时取消锁定材价勾选
          if (!record.provisionalEstimateName) {
            x.ifDonorMaterial = '0';
          } else {
            this.isFinish = true;
          }
          this.handleSaveRcjCache(x); // 更新缓存数据
        }
      });
    },
    setFooterHeight() {
      this.$nextTick(() => {
        const footerEl = this.$refs.footerInfo as HTMLDivElement;
        this.footerHeight = footerEl?.clientHeight || 0;
        console.log('setFooterHeight', this.footerHeight);
      });
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      this.rcjName = selectedKeys.toString();
      this.tableData = [];
      this.page = 1;
      this.getSingleSummary();
    },
    handleSearchReset() {
      this.rcjName = '';
      this.page = 1;
      this.tableData = [];
      this.getSingleSummary(3);
    },
    /* 提交重置 */
    handleReset() {
      // 取消提交数据时执行清除缓存操作
      this.handleResetVisible = true;
    },
    /* 提交重置弹框确定 */
    handleResetOk() {
      const sequenceNbr = this.projectBidSequenceNbr;
      if (!sequenceNbr) return '';
      let apiData = {
        constructId: sequenceNbr,
        typeList: this.typeList,
        page: this.page,
        limit: this.limit,
      };
      this.handleResetConfirmLoading = true;
      delRcjCache(apiData)
        .then((res) => {
          if (res.status === 200) {
            this.currentCacheFlag = false; //当前数据没有应用缓存
            this.handleResetVisible = false;
            this.getInit();
            this.getSingleSummary();
          }
        })
        .finally(() => {
          this.handleResetConfirmLoading = false;
        });
    },
    /* 提交重置弹框取消 */
    handleResetCancel() {
      this.handleResetVisible = false;
    },
    confirmAndReassemblePrice() {
      if (this.combinedPriceStatus) {
        return this.$message.warning('项目正在组价中');
      }
      if (this.isPriceAdjustmentOrValuation)
        return this.$message.error('项目工程量清单重新计价中');
      if (this.isPriceAdjustment)
        return this.$message.error('项目工程量清单存在人工调价未完成项');
      if (this.listGenerating)
        return this.$message.warning('项目工程量投标清单正在生成中');

      // const currentArr = this.tableData.filter(
      //   (item: any) =>
      //     item.kind != item.old_kind ||
      //     item.standardPrice != item.old_standardPrice ||
      //     item.ifLockStandardPrice != item.old_ifLockStandardPrice ||
      //     item.ifDonorMaterial != item.old_ifDonorMaterial ||
      //     item.donorMaterialName != item.old_donorMaterialName ||
      //     item.donorMaterialPrice != item.old_donorMaterialPrice
      // );
      // console.log('提交已更新的数据:', currentArr);

      let apiData = {
        conSequenceNbr: this.projectBidSequenceNbr,
        // renCaiJiModelList: currentArr,
      };
      this.btnLoading = true;
      confirmAndReassemblePrice(apiData).then((res) => {
        if (res.status === 200) {
          this.currentCacheFlag = false; //重新组件时重置是否使用缓存标志
          this.getInit();
          this.getSingleSummary();
          this.againMerge();
        }
      });
    },
    againMerge() {
      const bidSequenceNbr = this.projectBidSequenceNbr;
      coccMtdsResource_1_cocc_builder_constructMergeRepeat(bidSequenceNbr)
        .then((res) => {
          if (res.status === 200) {
            this.SET_PRICE_ADJUSTMENT_VALUATION(true);
            // this.startWebSocket();
            // this.priceAdjustmentOrValuationStatus();
          } else {
            this.SET_PRICE_ADJUSTMENT_VALUATION(false);
          }
        })
        .catch(() => {
          this.SET_PRICE_ADJUSTMENT_VALUATION(false);
        });
    },
  },
  // beforeDestroy() {
  //   window.removeEventListener('resize', this.getTableHeaderWidth, false);
  // },
});
