import { Vue, Component, Watch } from 'vue-property-decorator';
import {
  Subscription,
  fromEvent,
  map,
  startWith,
  filter,
  tap,
  Subject,
  combineLatestWith,
} from 'rxjs';

@Component({
  directives: {
    virtual: {
      bind(el) {
        const body = el.querySelector('.ant-table-body');
        const table = body?.querySelector('table');
        if (body && table) {
          const wrapper = document.createElement('div');
          wrapper.className = 'virtual-wrapper';
          wrapper.appendChild(table);
          body.appendChild(wrapper);
        }
      },
    },
  },
})
export default class extends Vue {
  first = -1;
  page = 0;
  limit = 10;
  defaultHeight = 54;
  renderData: any = [];
  tableData: any = [];
  el = document.body;
  data$ = new Subject();
  private subs = new Subscription();

  transformData<T = any>(
    data: T[]
  ): Array<T & { $pos: { index: number; top: number; height: number } }> {
    return data.map((item, i) => {
      const index = (this.page - 1) * this.limit + i;
      return {
        ...item,
        $pos: {
          index,
          top: index * this.defaultHeight,
          height: this.defaultHeight,
        },
      };
    });
  }

  @Watch('tableData')
  watchData(): void {
    this.data$.next(this.tableData);
    (this.el.querySelector('.virtual-wrapper') as HTMLElement).style.height =
      this.tableData.reduce((prev, curr) => prev + curr.$pos.height, 0) + 'px';
  }

  @Watch('first')
  watchFirst(): void {
    this.$nextTick(() => {
      const elems = this.el.querySelectorAll(
        'table tr'
      ) as NodeListOf<HTMLElement>;
      this.tableData = this.tableData.reduce((prev, curr, i) => {
        const index = i - this.first;
        const len = prev.length;
        curr.$pos.height =
          index < 0 || !elems[index]
            ? curr.$pos.height
            : elems[index].clientHeight;
        curr.$pos.top = len
          ? prev[len - 1].$pos.top + prev[len - 1].$pos.height
          : 0;
        prev.push(curr);
        return prev;
      }, []);
      elems.forEach((elem) => {
        elem.style.transform = `translateY(${this.renderData[0].$pos.top}px)`;
      });
    });
  }

  mounted(): void {
    this.el = (this.$refs.table as Vue).$el.querySelector(
      '.ant-table-body'
    ) as HTMLElement;
    const scroll$ = fromEvent(this.el, 'scroll').pipe(
      map(() => this.el.scrollTop),
      startWith(0)
    );
    const update$ = scroll$.pipe(
      combineLatestWith(this.data$),
      map(([st, data]) => {
        const first =
          data.find((item) => st <= item.$pos.top + item.$pos.height) ||
          data[data.length - 1];
        return [first?.$pos.index, data];
      }),
      filter(([first]) => first !== this.first),
      tap(([first]) => {
        this.first = first;
      })
    );
    const renderView$ = update$.pipe(
      map(([first, data]) => data.slice(first, first + this.limit))
    );
    this.subs.add(
      renderView$.subscribe((data) => {
        this.renderData = data;
      })
    );
  }

  destroyed(): void {
    this.subs.unsubscribe();
  }
}
